import React, { useRef, useState } from 'react';
import PlayCircleFilled from '@ant-design/icons/PlayCircleFilled';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Wrapper = styled.div`
  width: 100%;

  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 0 0 12px #ffffff10;
  background-color: #ffffff10;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0;

  &:before {
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    padding-top: 56.25%;
  }

  video {
    display: inline-block;
    width: 100%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .anticon {
    font-size: 70px;
    color: #ffffffd0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
    cursor: pointer;

    @media(max-width: 576px) {
      font-size: 50px;
    }

    &:hover {
      color: #ffffff;
      transform: translate(-50%, -50%) scale(1.1);
    }

    &:active {
      color: #ffffff;
      transition: all 0.1s;
      transform: translate(-50%, -50%);
    }
  }
`;

export default function AboveTheFoldVideo({ style }) {
  const videoRef = useRef();
  const [played, setPlayed] = useState(false);

  function play() {
    if (videoRef.current) {
      videoRef.current.play();
      setPlayed(true);
    }
  }

  return (
    <Wrapper style={{ ...style, fontSize: 0 }}>
      <video
        controls={played}
        ref={videoRef}
        preload="auto"
      >
        <source src="/videos/above-fold-video.mp4" type="video/mp4" />
      </video>

      {!played && <StaticImage
        src="../../assets/images/above-fold-video-poster.png"
        alt="WZRD Intro Video Poster"
        placeholder="blurred"
      />}

      {!played && <PlayCircleFilled onClick={play} />}
    </Wrapper>
  );
}
