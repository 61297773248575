import React, { useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col, Button, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import styled, { keyframes } from 'styled-components';
import AboveTheFoldVideo from './AboveTheFoldVideo';

const arrowAnimation = keyframes`
  from {
    transform: translateY(5px);
  }

  to {
    transform: translateY(-5px);
  }
`;

const StyledRow = styled(Row)`
  position: relative;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  margin: -88px -24px 0 -24px !important;
  padding-top: 88px;
  background: #000;
  align-content: flex-start;
  z-index: 0;

  button {
    height: 48px;
    padding: 0 24px;
    margin-top: 20px;
    background-color: #ffffff10 !important;
    // backdrop-filter: blur(10px);

    &:hover {
      border-color: white !important;
      color: white !important;
      background-color: #ffffff30 !important;
      transform: scale(1.05);
    }
    &:active {
      border-color: white !important;
      color: white !important;
      background-color: #ffffff40 !important;
      transform: scale(1);
    }
  }

  .ant-typography {
    color: white;
  }
  h2.ant-typography {
    font-size: 30px;
    max-width: 17.5em;
    margin-bottom: 0.8em;
  }
  div.ant-typography {
    font-size: 20px;
    font-weight: 300;
    max-width: 23em;
    border-radius: 8px;
    // background-color: #00000008;
    // backdrop-filter: blur(15px);
    padding: 5px 15px 8px 15px;
    margin-left: -15px;
  }
  @media(min-width: 576px) {
    h2.ant-typography {
      font-size: 40px;
    }
    div.ant-typography {
      font-size: 25px;
    }
  }
  @media(min-width: 768px) {
    h2.ant-typography {
      font-size: 50px;
    }
    div.ant-typography {
      font-size: 25px;
    }
  }

  .background {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translate(-10px, -10px);
    object-fit: cover;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
  }

  .arrow-down {
    color: white;
    opacity: 0.35;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    animation: ${arrowAnimation} 1.5s ease-in-out infinite;
    animation-direction: alternate;
  }
`;

const StyledVideo = styled.video`
  &::-webkit-media-controls-overlay-play-button {
    display: none;
  }
`;

export default function AboveTheFold() {
  const backgroundVideoRef = useRef();

  const { backgroundPlaceholder } = useStaticQuery(
    graphql`
      query {
        backgroundPlaceholder: file(relativePath: { eq: "images/background-placeholder.png" }) {
          childImageSharp {
            fixed(base64Width: 64) {
              base64
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (backgroundVideoRef.current) {
      backgroundVideoRef.current.playbackRate = 0.5;
    }
  });

  return (
    <StyledRow gutter={0} style={{ paddingLeft: 20, paddingRight: 20 }}>
      {isMobile ?
        <img src={backgroundPlaceholder.childImageSharp.fixed.base64} className="background" alt="WZRD Background Image" /> :
        <StyledVideo
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          poster={backgroundPlaceholder.childImageSharp.fixed.base64}
          ref={backgroundVideoRef}
          className="background"
        >
          <source src="/videos/background_2048x1792_blur_0.075_small.mp4" type="video/mp4" />
        </StyledVideo>
      }

      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 23, offset: 1 }}
        md={{ span: 22, offset: 1 }}
        style={{ paddingTop: '8vh' }}
      >
        <Typography>
          <Typography.Title level={2} style={{ marginTop: 0, fontWeight: 700 }}>
            Experience your sound
          </Typography.Title>
        </Typography>
      </Col>

      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 23, offset: 1 }}
        md={{ span: 10, offset: 1 }}
        xl={{ span: 9, offset: 1 }}
      >
        <Typography>
          <Typography.Paragraph>
            <span style={{ fontWeight: 900 }}>WZRD</span> augments your audio with immersive video powered by artificial intelligence
          </Typography.Paragraph>
        </Typography>

        <a href="https://app.wzrd.ai/signup">
          <Button size="large" ghost style={{ marginBottom: 60 }}>
            Get started now
          </Button>
        </a>
      </Col>

      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 22, offset: 1 }}
        md={{ span: 11, offset: 0 }}
        xl={{ span: 12, offset: 0 }}
      >
        <AboveTheFoldVideo style={{ marginBottom: 100 }} />
      </Col>
    </StyledRow>
  );
}
