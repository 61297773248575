import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { lightgrey } from '../../colors';

const Wrapper = styled.div`
  position: relative;
  // background: black;
  margin: 0 auto !important;
  padding: 200px 40px 200px 40px;
  max-width: 1020px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @media(max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ModelCard = styled(({ selected, disabled, ...props }) => <div {...props} />)`
  width: 450px;
  max-width: 100vw;
  margin: 0 auto;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.035);
  }

  @media(max-width: 450px) {
    margin: 0;
  }
  @media(max-width: 500px) {
    margin: 0;
    margin-left: -24px;
  }

  > div:nth-child(1) {
    width: 450px;
    height: 450px;
    line-height: 0;
    border-radius: 3px;
    overflow: hidden;
  }

  > div:nth-child(2) {
    padding: 24px 24px 10px 24px;
    display: flex;
    justify-content: space-between;

    h3 {
      margin-bottom: 0;
      line-height: 1;
      color: white;

      .ant-tag {
        margin-left: 10px;
        vertical-align: text-bottom;
      }
    }

    small {
      color: ${lightgrey[5]};
      font-size: 75%;
      line-height: 50px;
    }
  }
`;

// const Shape = styled(({ width, height, ...props }) => <div {...props} />)`
//   background-color: ${lightgrey[8]};
//   width: ${({ width }) => width / 50}px;
//   height: ${({ height }) => height / 50}px;
//   display: inline-block;
//   vertical-align: middle;
//   margin-right: 5px;
//   border-radius: 2px;
// `;

const StyledH2 = styled.h2`
  text-align: left;
  font-size: 50px;
  padding: 70px 0;
  color: white;

  @media(max-width: 576px) {
    font-size: 40px;
  }
`;

export default function VisualThemes() {
  const models = useStaticQuery(
    graphql`
      query {
        allWzrdModel {
          edges {
            node {
              cherry {
                childImageSharp {
                  gatsbyImageData(width: 450, height: 450)
                }
              }
              id
              name
              parameters {
                output_shape
              }
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <Row gutter={[30, 0]}>
        <Col span={24}>
          <StyledH2>
            Choose a theme<br />for your video...
          </StyledH2>
        </Col>
      </Row>

      {models.allWzrdModel.edges.map((edge) => {
        const model = edge.node;

        // const outputShape = ((model.parameters || {}).output_shape || [1024, 1024])
        // const shapeWidth = outputShape[0];
        // const shapeHeight = outputShape[1];
        
        return (
          <ModelCard key={model.id}>
            <div>
              <a href="https://app.wzrd.ai/signup">
                <GatsbyImage image={getImage(model.cherry)} alt={`${model.name} preview`} />
              </a>
            </div>
      
            {/* <div>
              <div>
                <h3>
                  {model.name}
                </h3>
      
                <Shape width={shapeWidth} height={shapeHeight} />
      
                <small>{shapeWidth}x{shapeHeight}</small>
              </div>
      
              <div>      
                <a href="https://app.wzrd.ai/signup">
                  <Button type="link">
                    Create project
                  </Button>
                </a>
              </div>
            </div> */}
          </ModelCard>
        );
      })}
    </Wrapper>
  );
}
