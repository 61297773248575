import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import Vimeo from './Vimeo';

const Wrapper = styled.div`
  position: relative;
  margin: 5vw -24px 0 -24px !important;
  padding-top: 75px;
  padding-bottom: 115px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 1124px;
    height: 100%;
    background: white;
    transform: translateX(-50%) skewY(-2.5deg);
    z-index: 0;
    border-radius: 5px;
    box-shadow: 0 0 0 12px #ffffff10;
  }
`;

const StyledH2 = styled.h2`
  text-align: center;
  margin-bottom: 60px;
  font-size: 30px;
  position: relative;
  z-index: 1;

  @media(min-width: 576px) {
    font-size: 35px;
  }
  @media(min-width: 768px) {
    font-size: 40px;
  }
`;

const ImageRow = styled.div`
  padding: 0 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  max-width: 1124px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media(max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  @media(max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding-left: 24px;
    padding-right: 24px;
  }

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  
  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  h4 {
    margin-top: 3px;
    font-size: 19px !important;
    display: none;

    // @media(max-width: 768px) {
    //   display: none;
    // }
  }

  .lazyload-wrapper {
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
  }
`;

export default function MadeByWzrd() {
  return (
    <Wrapper>
      <StyledH2>Made with <span style={{ fontWeight: 900 }}>WZRD</span> 🤙</StyledH2>

      <ImageRow gutter={[40, 0]}>
        <div>
          <LazyLoad once height={400}>
            <Vimeo videoId={369531132} square />
          </LazyLoad>

          <Typography.Title level={4}>When a painting comes to life</Typography.Title>
        </div>

        <div>
          <LazyLoad once height={400}>
            <Vimeo videoId={369537979} />
          </LazyLoad>

          <Typography.Title level={4}>The Egg</Typography.Title>
        </div>

        <div>
          <LazyLoad once height={400}>
            <Vimeo videoId={378525737} />
          </LazyLoad> 

          <Typography.Title level={4}>Reflections</Typography.Title>
        </div>

        <div>
          <LazyLoad once height={400}>
            <Vimeo videoId={466429168} />
          </LazyLoad> 

          <Typography.Title level={4}>Reid Willis - The Ocean Won’t Allow</Typography.Title>
        </div>
      </ImageRow>
    </Wrapper>
  );
}
