import React from 'react';
import styled from 'styled-components';
import { brand } from '../../colors';

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  background: black;

  .inner {
    left: 50%;
    padding-top: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
      position:absolute;
      right: 0;
      top: 0;

      #player {
        height: 100% !important;
      }
    }
  }
`;

export default function VimeoWrapper({ videoId, style, wrapped = true }) {
  const iframe = (
    <iframe
      title="vimeo-player"
      src={`https://player.vimeo.com/video/${videoId}?byline=0&color=${brand.primary}&square=true`}
      frameBorder="0"
      allowFullScreen
      style={wrapped ? {} : style}
    />
  );

  if (wrapped) {
    return (
      <Wrapper style={style}>
        <div className="inner">
          {iframe}
        </div>
      </Wrapper>
    );
  }

  return iframe;
}
