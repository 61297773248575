import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AboveTheFold from '../components/index/AboveTheFold';
import MadeByWzrd from '../components/index/MadeByWzrd';
import Uses from '../components/index/Uses';
import PoweredBy from '../components/index/PoweredBy';
import Ready from '../components/index/Ready';
import VisualThemes from '../components/index/VisualThemes';

export default function Home() {
  return (
    <Layout location="/">
      <SEO title="AI Music Visualizer" description="WZRD is an AI music visualizer. It's a music video generator that augments your audio with immersive video powered by artificial intelligence." />
      
      <style>
        {`body {
          background: black;
        }`}
      </style>

      <AboveTheFold />

      <MadeByWzrd />

      <Uses />

      <PoweredBy />

      <VisualThemes />

      <Ready />
    </Layout>
  );
}
