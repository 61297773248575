import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin: 0 -10px -50px -10px !important;
  padding: 0 0 200px 0;
  text-align: center;
`;

const StyledH2 = styled.h2`
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
  color: white;

  @media(min-width: 576px) {
    font-size: 35px;
  }
  @media(min-width: 768px) {
    font-size: 40px;
  }
`;

export default function Ready() {
  return (
    <Wrapper>
      <StyledH2>
        <span style={{ fontWeight: 900 }}>Ready to try it out?</span><br />
        <small style={{ fontWeight: 300 }}>Create a video in a couple of minutes</small>
      </StyledH2>

      <a href="https://app.wzrd.ai/signup">
        <Button size="large" type="primary">
          Get started now
        </Button>
      </a>
    </Wrapper>
  );
}
