import React from 'react';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';

const StyledRow = styled(Row)`
  margin: 0 auto !important;
  max-width: 1080px;
  padding: 175px 0;
  display: flex !important;
  align-items: stretch;
  flex-wrap: wrap;

  .ant-col {
    margin-bottom: 15px;

    @media(max-width: 768px) {
      margin-bottom: 50px !important;
    }

    @media(max-width: 576px) {
      padding: 0 !important;
    }

    &:first-child {
      margin-bottom: 0 !important;
    }
  }

  .lazyload-wrapper {
    text-align: center;
  }

  img {
    width: 66%;
    margin: 16.9% auto 40px auto;
  }
`;

const StyledH2 = styled.h2`
  text-align: left;
  color: white;
  font-size: 30px;
  margin-bottom: 70px;

  @media(min-width: 576px) {
    font-size: 35px;
  }
  @media(min-width: 768px) {
    font-size: 40px;
  }
  @media(max-width: 576px) {
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background: transparent;
  border: none;
  height: 100%;
  max-width: 420px;
  margin: 0 auto;

  .ant-card-cover {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    aspect-ratio: 1 / 1;

    > div {
      z-index: 1;
    }
    
    &:after {
      content: '';
      position: absolute;
      background: white;
      top: 50%;
      left: 50%;
      width: 90%;
      z-index: 0;
      transform: translate(-50%, -47%);
      border-radius: 100%;
      aspect-ratio: 1 / 1;
      box-shadow: 0 0 0 12px #ffffff10;
    }
  }

  .ant-card-meta-title {
    color: white;
    font-weight: 900;
  }
  .ant-card-meta-description {
    color: rgba(255,255,255,0.8);
    min-height: 125px;

    @media(max-width: 768px) {
      min-height: auto;
    }
  }
`;

const columnProps = {
  xs: { span: 24, offset: 0 },
  sm: { span: 24, offset: 0 },
  md: { span: 8, offset: 0 },
};

export default function Uses() {
  const images = useStaticQuery(
    graphql`
      query {
        illustrationMusic: file(relativePath: { eq: "images/illustration-music.svg" }) {
          publicURL
        }
        illustrationAds: file(relativePath: { eq: "images/illustration-ads.svg" }) {
          publicURL
        }
        illustrationParty: file(relativePath: { eq: "images/illustration-party.svg" }) {
          publicURL
        }
      }
    `
  );
  
  return (
    <StyledRow gutter={{ xs: 0, sm: 10, md: 20, lg: 50 }}>
      <Col span={24}>
        <StyledH2>
          <span style={{ fontWeight: 900 }}>WZRD</span> is built for <span style={{ fontWeight: 900, fontSize: '140%', fontStyle: 'italic' }}>CREATORS</span>
        </StyledH2>
      </Col>

      <Col {...columnProps}>
        <StyledCard
          cover={<LazyLoad once offset={500} height={500}><img src={images.illustrationMusic.publicURL} alt="illu-music" /></LazyLoad>}
        >
          <Card.Meta
            title="Musical artists"
            description="With our AI music video generator you can take your music video and live performance to the next level."
          />
        </StyledCard>
      </Col>

      <Col {...columnProps}>
        <StyledCard
          cover={<LazyLoad once offset={500} height={500}><img src={images.illustrationAds.publicURL} alt="illu-ads" /></LazyLoad>}
        >
          <Card.Meta
            title="Advertisers"
            description={(
              <span>Embed the mindblowing visuals from our wizard AI music video generator in your next ad.</span>
            )}
          />
        </StyledCard>
      </Col>

      <Col {...columnProps}>
        <StyledCard
          cover={<LazyLoad once offset={500} height={500}><img src={images.illustrationParty.publicURL} alt="illu-party" /></LazyLoad>}
        >
          <Card.Meta
            title="Events"
            description={(
              <span>Create an unprecedented visual experience for your next event using our AI music video generator.</span>
            )}
          />
        </StyledCard>
      </Col>
    </StyledRow>
  );
}
