import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const Wrapper = styled.div`
  position: relative;
  margin: 0 -24px;
  position: relative;

  @media(max-width: 576px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 750px;
    height: 100%;
    background: white;
    transform: translateX(-50%) skewY(-2.5deg);
    z-index: 0;
    border-radius: 5px;
    box-shadow: 0 0 0 12px #ffffff10;
  }
`;

const StyledRow = styled(Row)`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-top: 75px;
  padding-bottom: 115px;
  max-width: 620px;
`;

const StyledH2 = styled.h2`
  text-align: left;
  font-size: 30px;
  margin-bottom: 40px;

  @media(min-width: 576px) {
    font-size: 35px;
  }
  @media(min-width: 768px) {
    font-size: 40px;
  }
`;

const StyledH3 = styled.h3`
  text-align: left;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 15px;
`;

export default function PoweredBy() {
  return (
    <Wrapper>
      <StyledRow>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
        >
          <StyledH2>
            How does this <span style={{ fontWeight: 900 }}>magic</span> ✨ work?
          </StyledH2>

          <StyledH3>🔊&nbsp;&nbsp;Audio analysis</StyledH3>
          Our audio analysis pipeline can detect percussion and harmonic elements, which are used to drive different parts of the video.


          <StyledH3>👁&nbsp;&nbsp;Visual engine</StyledH3>
          <span style={{ fontWeight: 900 }}>WZRD</span>'s magic works by using audio elements to drive a machine learning technique called a neural network. This results in an entirely new kind of visual experience.


          <StyledH3>🎥&nbsp;&nbsp;Video render</StyledH3>
          Finally, a full video render is performed and combined with your audio. Enjoy!
        </Col>
      </StyledRow>
    </Wrapper>
  );
}
